import store from "@/store";
import { useOidcStore } from "@points/common";
import { NavigationGuardNext, Route } from "vue-router";

export async function authNavigationGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    const oidcStore = useOidcStore();
    if (to.matched.some(record => record.meta.requiresAuth) &&
        oidcStore.isAuthenticated &&
        !store.state.currentUser) {
        try {
            await store.dispatch("loadCurrentUser");
            if (oidcStore.roles.includes("PointSAdmin")) {
                next();
            } else {
                next({ name: "forbidden" });
            }
        } catch {
            next({ name: "forbidden" });
        }
    } else {
        next();
    }
}
