import { getCurrentUser } from "@/api";
import { User } from "@/models";
import { initializeOidc } from "@points/common";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import campaign from "./modules/campaign";
import chart from "./modules/chart";
import company from "./modules/company";
import customerList from "./modules/customer-list";
import loyaltyProgram from "./modules/loyalty-program";
import promotion from "./modules/promotion";
import reward from "./modules/reward";
import template from "./modules/template";

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();

export interface MarketingState {
    currentUser?: User;
}

Vue.use(Vuex);

initializeOidc("points.marketing");

export default new Vuex.Store<MarketingState>({
    strict: process.env.NODE_ENV !== "production",

    modules: {
        campaign,
        company,
        "customer-list": customerList,
        "loyalty-program": loyaltyProgram,
        promotion,
        reward,
        template,
        chart
    },

    state: {
        currentUser: undefined
    },

    mutations: {
        setCurrentUser(state, currentUser: User): void {
            state.currentUser = currentUser;
        }
    },

    actions: {
        async loadCurrentUser(context: ActionContext<MarketingState, MarketingState>): Promise<void> {
            const user = await getCurrentUser();
            context.commit("setCurrentUser", user);
        }
    }
});
