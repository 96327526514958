import axios from "axios";
import * as retryAxios from "retry-axios";

axios.defaults.baseURL = `${process.env.VUE_APP_CENTRAL}/api/`;

// Adds default exponential retry 3 times for 100-199, 429, and 500-599 status codes
if (process.env.NODE_ENV === "production") {
    retryAxios.attach();
}

axios.interceptors.request.use(config => {
    if (config.method === "patch") {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers["Content-Type"] = "application/json-patch+json;charset=UTF-8";
    }
    return config;
}, error => {
    return Promise.reject(error);
});
