/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { compare } from "fast-json-patch";

export default {
    namespaced: true,
    state: {
        newPromotion: {},
        promotions: [],
        getLoading: false,
        postLoading: false
    },
    actions: {
        async getPromotions({ commit }) {
            commit("setGetLoading", true);
            const response = await axios({
                method: "GET",
                url: "v1/Promotion"
            });

            for (const promotion of response.data.items) {
                if (promotion.startDateUtc) {
                    promotion.startDateUtc = promotion.startDateUtc.split(
                        "T"
                    )[0];
                }
                if (promotion.endDateUtc) {
                    promotion.endDateUtc = promotion.endDateUtc.split("T")[0];
                }

                promotion.linkedStoresDisplay = promotion.linkedStores.map(store => store.storeId);
            }
            commit("setPromotions", response.data.items);
            commit("setGetLoading", false);
        },
        async savePromotion({ state, commit, dispatch }) {
            commit("setPostLoading", true);
            commit("setNewPromotion", { ...state.newPromotion });

            let storeIds = state.newPromotion.linkedStoresDisplay;

            if (state.newPromotion.promotionId) {
                const oldPromotionIndex = state.promotions.findIndex(
                    (promotion) =>
                        promotion.promotionId ===
                        state.newPromotion.promotionId
                );
                const oldPromotion = state.promotions[oldPromotionIndex];

                const oldStoreIds = oldPromotion.linkedStoresDisplay;

                storeIds = state.newPromotion.linkedStoresDisplay.filter(storeId => !oldStoreIds.includes(storeId));
                const removedStoreIds = oldStoreIds.filter(storeId => !state.newPromotion.linkedStoresDisplay.includes(storeId));
                var removedStores = state.newPromotion.linkedStores.filter(store => removedStoreIds.includes(store.storeId));

                delete oldPromotion.linkedStores;
                delete oldPromotion.linkedStoresDisplay;
                delete state.newPromotion.linkedStores;
                delete state.newPromotion.linkedStoresDisplay;

                const patchDocument = compare(
                    oldPromotion,
                    state.newPromotion
                );
                await axios.patch(`v1/Promotion/${state.newPromotion.promotionId}`,
                    patchDocument);
            } else {
                await axios({
                    method: "POST",
                    url: "v1/Promotion",
                    data: state.newPromotion
                });
                await dispatch("getPromotions");
                commit("setNewPromotion", { ...state.newPromotion, promotionId: state.promotions[state.promotions.length - 1].promotionId });
            }

            for (const storeId of storeIds) {
                await axios({
                    method: "POST",
                    url: "v1/PromotionStoreLink",
                    data: { storeId, promotionId: state.newPromotion.promotionId }
                });
            }

            for (const store of removedStores) {
                await axios({
                    method: "DELETE",
                    url: `v1/PromotionStoreLink/${store.promotionStoreLinkId}`
                });
            }

            dispatch("getPromotions");

            commit("setNewPromotion", {});
            commit("setPostLoading", false);
        },
        async savePromotionImages({ state, commit, dispatch }, formattedImages) {
            commit("setPostLoading", true);

            if (formattedImages.length > 0) {
                for (const image of formattedImages) {
                    image.promotionId = state.newPromotion.promotionId;
                    await axios({
                        method: "POST",
                        url: "v1/PromotionImage",
                        data: image
                    });
                }
            }

            dispatch("getPromotions");

            commit("setNewPromotion", {});
            commit("setPostLoading", false);
        },
        async deletePromotion({ state, commit }, promotionId) {
            await axios({
                method: "DELETE",
                url: `v1/Promotion/${promotionId}`
            });

            const promotions = state.promotions.filter(
                (promotion) =>
                    promotion.promotionId !== promotionId
            );
            commit("setPromotions", promotions);
        },
        duplicatePromotion({ dispatch, commit }, promotion) {
            promotion.promotionId = null;
            commit("setNewPromotion", promotion);
            dispatch("savePromotion");
        }
    },
    getters: {

    },
    mutations: {
        setGetLoading(state, getLoading) {
            state.getLoading = getLoading;
        },
        setPostLoading(state, postLoading) {
            state.postLoading = postLoading;
        },
        setPromotions(state, promotions) {
            state.promotions = promotions;
        },
        setNewPromotion(state, newPromotion) {
            state.newPromotion = newPromotion;
        }
    }
};
