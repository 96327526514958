import { Company, StoreLocation, StoreResponse, StoreServiceCategory } from "@/models";
import { HttpStatusCode, Page, ResponseDto } from "@points/common";
import axios from "axios";

export async function getCompany(companyId: number): Promise<Company> {
    if (!companyId) {
        throw new Error("companyId is required");
    }

    const response = await axios.get<Company>("company/get", {
        params: {
            CompanyNumber: companyId
        }
    });

    return response.data;
}

export async function getStoreLocations(companyId: number): Promise<StoreLocation[]> {
    if (!companyId) {
        throw new Error("companyId is required");
    }

    const response = await axios.get<ResponseDto<StoreResponse>>("store/GetStores", {
        params: {
            CompanyNumber: companyId
        }
    });

    if (response.status === HttpStatusCode.Ok && response.data.success && response.data.result) {
        return response.data.result.stores;
    } else {
        return [];
    }
}

export async function getStoreServiceCategories(): Promise<StoreServiceCategory[]> {
    const response = await axios.get<Page<StoreServiceCategory>>("v1/StoreServiceCategory");
    return response.data.items;
}
