/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { compare } from "fast-json-patch";

export default {
    namespaced: true,
    state: {
        newCustomerList: {},
        customerLists: [],
        getLoading: false,
        postLoading: false
    },
    actions: {
        async getCustomerLists({ commit }) {
            commit("setGetLoading", true);
            const response = await axios({
                method: "GET",
                url: "v1/CustomerList"
            });

            commit("setCustomerLists", response.data.items);
            commit("setGetLoading", false);
        },
        async saveCustomerList({ state, rootState, commit, dispatch }) {
            commit("setPostLoading", true);
            commit("setNewCustomerList", { ...state.newCustomerList });

            if (state.newCustomerList.customerListId) {
                const oldCustomerListIndex = state.customerLists.findIndex(
                    (customerList) =>
                        customerList.customerListId ===
                        state.newCustomerList.customerListId
                );
                const oldCustomerList = state.customerLists[oldCustomerListIndex];
                const patchDocument = compare(
                    oldCustomerList,
                    state.newCustomerList
                );
                await axios.patch(`v1/CustomerList/${state.newCustomerList.customerListId}`,
                    patchDocument);
            } else {
                await axios({
                    method: "POST",
                    url: "v1/CustomerList",
                    data: state.newCustomerList
                });
            }

            dispatch("getCustomerLists");

            commit("setNewCustomerList", {});
            commit("setPostLoading", false);
        },
        async deleteCustomerList({ state, commit }, customerListId) {
            await axios({
                method: "DELETE",
                url: `v1/CustomerList/${customerListId}`
            });

            const customerLists = state.customerLists.filter(
                (customerList) =>
                    customerList.customerListId !== customerListId
            );
            commit("setCustomerLists", customerLists);
        },
        duplicateCustomerList({ dispatch, commit }, customerList) {
            customerList.customerListId = null;
            commit("setNewCustomerList", customerList);
            dispatch("saveCustomerList");
        }
    },
    getters: {

    },
    mutations: {
        setGetLoading(state, getLoading) {
            state.getLoading = getLoading;
        },
        setPostLoading(state, postLoading) {
            state.postLoading = postLoading;
        },
        setCustomerLists(state, customerLists) {
            state.customerLists = customerLists;
        },
        setNewCustomerList(state, newCustomerList) {
            state.newCustomerList = newCustomerList;
        }
    }
};
