import { deleteTemplate, getTemplate, getTemplates, patchTemplate, postTemplate } from "@/api";
import { ContentType, Template, TemplateType } from "@/models";
import { MarketingState } from "@/store";
import { ActionContext, Module } from "vuex";

interface TemplateState {
    templates: Template[];
    currentTemplate: Template;
    newTemplate: Template;
}

export interface TemplateQuery {
    type: TemplateType;
    contentType: ContentType;
}

const templateModule: Module<TemplateState, MarketingState> = {
    namespaced: true,

    state: {
        templates: [],
        currentTemplate: {},
        newTemplate: {}
    },

    mutations: {
        setTemplates(state: TemplateState, templates: Template[]): void {
            state.templates = templates;
        },

        removeTemplate(state: TemplateState, id: number): void {
            const index = state.templates.findIndex(template => template.id === id);
            state.templates.splice(index, 1);
        },

        setCurrentTemplate(state: TemplateState, currentTemplate: Template): void {
            state.currentTemplate = currentTemplate;
        },

        setNewTemplate(state: TemplateState, newTemplate: Template): void {
            state.newTemplate = newTemplate;
        },

        setNewTemplateName(state: TemplateState, name: string): void {
            state.newTemplate.name = name;
        },

        setNewTemplateContent(state: TemplateState, content: string): void {
            state.newTemplate.content = content;
        }
    },

    actions: {
        async searchTemplates(context: ActionContext<TemplateState, MarketingState>, query: TemplateQuery): Promise<Template[]> {
            if (!query.type) {
                throw new Error("type is required");
            }
            if (!query.contentType) {
                throw new Error("contentType is required");
            }

            const filter = `type eq '${query.type}' and contentType eq '${query.contentType}'`;

            const templates = await getTemplates(filter, "name", "asc");
            context.commit("setTemplates", templates);
            return templates;
        },

        async loadTemplate(context: ActionContext<TemplateState, MarketingState>, id: number): Promise<Template> {
            const template = await getTemplate(id);
            context.commit("setCurrentTemplate", Object.assign({}, template));
            context.commit("setNewTemplate", Object.assign({}, template));
            return template;
        },

        async createTemplate(context: ActionContext<TemplateState, MarketingState>): Promise<Template> {
            const template = await postTemplate(context.state.newTemplate);
            context.commit("setCurrentTemplate", Object.assign({}, template));
            context.commit("setNewTemplate", Object.assign({}, template));
            return template;
        },

        async updateTemplate(context: ActionContext<TemplateState, MarketingState>): Promise<Template> {
            if (!context.state.currentTemplate.id) {
                throw new Error("currentTemplate hasn't been loaded");
            }

            if (!context.state.newTemplate.id) {
                throw new Error("newTemplate hasn't been loaded");
            }

            const template = await patchTemplate(context.state.currentTemplate, context.state.newTemplate);
            context.commit("setCurrentTemplate", Object.assign({}, template));
            context.commit("setNewTemplate", Object.assign({}, template));
            return template;
        },

        async deleteTemplate(context: ActionContext<TemplateState, MarketingState>): Promise<void> {
            if (!context.state.currentTemplate.id) {
                throw new Error("currentTemplate hasn't been loaded");
            }

            await deleteTemplate(context.state.currentTemplate.id);
            context.commit("removeTemplate", context.state.currentTemplate.id);
            context.commit("setCurrentTemplate", {});
            context.commit("setNewTemplate", {});
        }
    },

    getters: {
        defaultTemplates: (state): Template[] => state.templates.filter(template => template.isGlobal),
        savedTemplates: (state): Template[] => state.templates.filter(template => !template.isGlobal)
    }
};

export default templateModule;
