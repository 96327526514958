import { Template } from "@/models";
import { Page } from "@points/common";
import axios from "axios";
import { compare } from "fast-json-patch";

export async function getTemplates(filter = "", orderBy = "", orderDirection: "asc" | "desc" = "asc"): Promise<Template[]> {
    const response = await axios.get<Page<Template>>(`${process.env.VUE_APP_CENTRAL}/messaging/api/templates`, {
        params: {
            $filter: filter,
            $orderBy: `${orderBy} ${orderDirection}`
        }
    });

    return response.data.items;
}

export async function getTemplate(id: number): Promise<Template> {
    if (!id) {
        throw new Error("id is required");
    }

    const response = await axios.get<Template>(`${process.env.VUE_APP_CENTRAL}/messaging/api/templates/${id}`);
    return response.data;
}

export async function postTemplate(template: Template): Promise<Template> {
    const response = await axios.post<Template>("${process.env.VUE_APP_CENTRAL}/messaging/api/templates", template);
    return response.data;
}

export async function patchTemplate(oldTemplate: Template, newTemplate: Template): Promise<Template> {
    const patchDocument = compare(oldTemplate, newTemplate);
    const response = await axios.patch<Template>(`${process.env.VUE_APP_CENTRAL}/messaging/api/templates/${newTemplate.id}`, patchDocument);
    return response.data;
}

export async function deleteTemplate(id: number): Promise<void> {
    if (!id) {
        throw new Error("id is required");
    }

    await axios.delete(`v1/templates/${id}`);
}
