import Common from "@points/common";
import VueCompositionApi from "@vue/composition-api";
import Vue, { VNode } from "vue";
import App from "./App.vue";
import "./filters";
import "./plugins/axios";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store, { pinia } from "./store";
import "./styles/app.scss";

Vue.config.productionTip = false;
Vue.use(VueCompositionApi);
Vue.use(Common);
// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require("vue-moment"));

const { hostname, href, protocol } = location;
if (hostname !== "localhost" && protocol === "http:") {
    location.replace(href.replace("http://", "https://"));
}

new Vue({
    router,
    store,
    pinia,
    vuetify,
    render: (h): VNode => h(App)
}).$mount("#app");
