/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { compare } from "fast-json-patch";

export default {
    namespaced: true,
    state: {
        newLoyaltyProgram: {},
        loyaltyPrograms: [],
        getLoading: false,
        postLoading: false
    },
    actions: {
        async getLoyaltyPrograms({ commit }) {
            commit("setGetLoading", true);
            const response = await axios({
                method: "GET",
                url: "v1/LoyaltyProgram"
            });

            for (const loyaltyProgram of response.data.items) {
                loyaltyProgram.loyaltyProgramStoreLinkDisplay = loyaltyProgram.loyaltyProgramStoreLinks.map(storeLink => storeLink.storeId);
            }

            commit("setLoyaltyPrograms", response.data.items);
            commit("setGetLoading", false);
        },
        async saveLoyaltyProgram({ state, commit, dispatch }) {
            commit("setPostLoading", true);
            commit("setNewLoyaltyProgram", { ...state.newLoyaltyProgram });

            let storeIds = state.newLoyaltyProgram.loyaltyProgramStoreLinkDisplay;

            if (state.newLoyaltyProgram.loyaltyProgramId) {
                const oldLoyaltyProgramIndex = state.loyaltyPrograms.findIndex(
                    (loyaltyProgram) =>
                        loyaltyProgram.loyaltyProgramId ===
                        state.newLoyaltyProgram.loyaltyProgramId
                );
                const oldLoyaltyProgram = state.loyaltyPrograms[oldLoyaltyProgramIndex];

                const oldStoreIds = oldLoyaltyProgram.loyaltyProgramStoreLinkDisplay;

                storeIds = state.newLoyaltyProgram.loyaltyProgramStoreLinkDisplay.filter(storeId => !oldStoreIds.includes(storeId));
                const removedStoreIds = oldStoreIds.filter(storeId => !state.newLoyaltyProgram.loyaltyProgramStoreLinkDisplay.includes(storeId));
                var removedStores = state.newLoyaltyProgram.loyaltyProgramStoreLinks.filter(store => removedStoreIds.includes(store.storeId));

                delete oldLoyaltyProgram.loyaltyProgramStoreLinkDisplay;
                delete state.newLoyaltyProgram.loyaltyProgramStoreLinkDisplay;
                delete oldLoyaltyProgram.loyaltyProgramStoreLinks;
                delete state.newLoyaltyProgram.loyaltyProgramStoreLinks;

                const patchDocument = compare(
                    oldLoyaltyProgram,
                    state.newLoyaltyProgram
                );
                await axios.patch(`v1/LoyaltyProgram/${state.newLoyaltyProgram.loyaltyProgramId}`,
                    patchDocument);
            } else {
                await axios({
                    method: "POST",
                    url: "v1/Loyaltyprogram",
                    data: state.newLoyaltyProgram
                });
                await dispatch("getLoyaltyPrograms");
                commit("setNewLoyaltyProgram", { ...state.newLoyaltyProgram, loyaltyProgramId: state.loyaltyPrograms[state.loyaltyPrograms.length - 1].loyaltyProgramId });
            }

            for (const storeId of storeIds) {
                await axios({
                    method: "POST",
                    url: "v1/LoyaltyProgramStoreLink",
                    data: {
                        storeId,
                        loyaltyProgramId: state.newLoyaltyProgram.loyaltyProgramId
                    }
                });
            }

            if (removedStores) {
                for (const store of removedStores) {
                    await axios({
                        method: "DELETE",
                        url: `v1/LoyaltyProgramStoreLink/${store.loyaltyProgramStoreLinkId}`
                    });
                }
            }

            dispatch("getLoyaltyPrograms");

            commit("setNewLoyaltyProgram", {});
            commit("setPostLoading", false);
        },
        async deleteLoyaltyProgram({ state, commit }, loyaltyProgramId) {
            await axios({
                method: "DELETE",
                url: `v1/LoyaltyProgram/${loyaltyProgramId}`
            });

            const loyaltyPrograms = state.loyaltyPrograms.filter(
                (loyaltyProgram) =>
                    loyaltyProgram.loyaltyProgramId !== loyaltyProgramId
            );
            commit("setLoyaltyPrograms", loyaltyPrograms);
        },
        duplicateLoyaltyProgram({ dispatch, commit }, loyaltyProgram) {
            loyaltyProgram.loyaltyProgramId = null;
            commit("setNewLoyaltyProgram", loyaltyProgram);
            dispatch("saveLoyaltyProgram");
        }
    },
    getters: {

    },
    mutations: {
        setGetLoading(state, getLoading) {
            state.getLoading = getLoading;
        },
        setPostLoading(state, postLoading) {
            state.postLoading = postLoading;
        },
        setLoyaltyPrograms(state, loyaltyPrograms) {
            state.loyaltyPrograms = loyaltyPrograms;
        },
        setNewLoyaltyProgram(state, newLoyaltyProgram) {
            state.newLoyaltyProgram = newLoyaltyProgram;
        }
    }
};
