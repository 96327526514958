import { getCompany, getStoreLocations, getStoreServiceCategories } from "@/api";
import { Company, StoreLocation, StoreServiceCategory } from "@/models";
import { MarketingState } from "@/store";
import { ActionContext, Module } from "vuex";

interface CompanyState {
    company: Company;
    storeServiceCategories: StoreServiceCategory[];
    storeLocations: StoreLocation[];
}

const companyModule: Module<CompanyState, MarketingState> = {
    namespaced: true,

    state: {
        company: {},
        storeServiceCategories: [],
        storeLocations: []
    },

    mutations: {
        setCompany(state: CompanyState, company: Company): void {
            state.company = company;
        },

        setStoreLocations(state: CompanyState, storeLocations: StoreLocation[]): void {
            state.storeLocations = storeLocations;
        },

        setStoreServiceCategories(state: CompanyState, storeServiceCategories: StoreServiceCategory[]): void {
            state.storeServiceCategories = storeServiceCategories;
        }
    },

    actions: {
        async loadCompany(context: ActionContext<CompanyState, MarketingState>): Promise<Company> {
            const company = await getCompany(context.rootState.currentUser?.companyId || -1);
            context.commit("setCompany", company);
            return company;
        },

        async loadStoreLocations(context: ActionContext<CompanyState, MarketingState>): Promise<StoreLocation[]> {
            const storeLocations = await getStoreLocations(context.rootState.currentUser?.companyId || -1);
            context.commit("setStoreLocations", storeLocations);
            return storeLocations;
        },

        async loadStoreServiceCategories(context: ActionContext<CompanyState, MarketingState>): Promise<StoreServiceCategory[]> {
            const storeServiceCategories = await getStoreServiceCategories();
            context.commit("setStoreServiceCategories", storeServiceCategories);
            return storeServiceCategories;
        }
    }
};

export default companyModule;
