






































































































import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import Vue from "vue";
import { Role } from "./models";
import { isInRole, hasAccess } from "./utilities";

export default Vue.extend({
    name: "App",

    data: () => ({
        drawer: false,
        navigationItems: [
            {
                title: "Home",
                icon: "mdi-home",
                routeName: "home",
                role: Role.Admin
            },
            {
                title: "Campaigns",
                icon: "mdi-email",
                routeName: "campaigns",
                role: Role.Admin
            },
            {
                title: "Customer Lists",
                icon: "mdi-account-multiple",
                routeName: "customer-lists",
                role: Role.Admin
            },
            {
                title: "Promotions",
                icon: "mdi-cash-usd",
                routeName: "promotions",
                role: Role.Admin
            },
            {
                title: "Rewards",
                icon: "mdi-cash",
                routeName: "rewards",
                role: Role.Admin
            },
            {
                title: "Loyalty Programs",
                icon: "mdi-currency-usd",
                routeName: "loyalty-programs",
                role: Role.Admin
            },
            {
                title: "Charts",
                icon: "mdi-chart-box",
                routeName: "charts",
                role: Role.Admin
            }
        ]
    }),

    mounted(): void {
        this.configureApplicationInsights();
    },

    methods: {
        isInRole,
        hasAccess,

        configureApplicationInsights(): void {
            const appInsights = new ApplicationInsights({ config: {
                instrumentationKey: process.env.VUE_APP_APPLICATIONINSIGHTS_INSTRUMENTATIONKEY,
                distributedTracingMode: DistributedTracingModes.W3C,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true
            } });
            appInsights.loadAppInsights();
            appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
        }
    }
});
