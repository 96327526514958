import { deleteCampaign, getCampaign, getCampaigns, patchCampaign, postCampaign } from "@/api";
import { Campaign, CampaignScheduleType, CampaignTrigger } from "@/models";
import { MarketingState } from "@/store";
import { CancelToken } from "axios";
import { ActionContext, Module } from "vuex";

interface CampaignState {
    campaignsQuery: string;
    campaignsOrderBy: string;
    campaignsFilterBy: string;
    campaigns: Campaign[];
    currentCampaign: Campaign;
    newCampaign: Campaign;
}

const campaignModule: Module<CampaignState, MarketingState> = {
    namespaced: true,

    state: {
        campaignsQuery: "",
        campaignsOrderBy: "modified",
        campaignsFilterBy: "",
        campaigns: [],
        currentCampaign: {},
        newCampaign: {}
    },

    mutations: {
        setCampaignsQuery(state: CampaignState, query: string): void {
            state.campaignsQuery = query;
        },

        setCampaignsOrderBy(state: CampaignState, orderBy: string): void {
            state.campaignsOrderBy = orderBy;
        },

        setCampaignsFilterBy(state: CampaignState, campaignsFilterBy: string): void {
            state.campaignsFilterBy = campaignsFilterBy;
        },

        setCampaigns(state: CampaignState, campaigns: Campaign[]): void {
            state.campaigns = campaigns;
        },

        removeCampaign(state: CampaignState, id: number): void {
            const index = state.campaigns.findIndex(campaign => campaign.id === id);
            state.campaigns.splice(index, 1);
        },

        setCurrentCampaign(state: CampaignState, currentCampaign: Campaign): void {
            state.currentCampaign = currentCampaign;
        },

        setNewCampaign(state: CampaignState, newCampaign: Campaign): void {
            state.newCampaign = newCampaign;
        },

        setNewCampaignStoreIds(state: CampaignState, storeIds: number[]): void {
            state.newCampaign.storeIds = storeIds;
        },

        setNewCampaignCustomerListIds(state: CampaignState, customerListIds: number[]): void {
            state.newCampaign.customerListIds = customerListIds;
        },

        setNewCampaignPublished(state: CampaignState, published: string): void {
            state.newCampaign.published = published;
        },

        setNewCampaignName(state: CampaignState, name: string): void {
            state.newCampaign.name = name;
        },

        setNewCampaignSender(state: CampaignState, sender: string): void {
            state.newCampaign.sender = sender;
        },

        setNewCampaignSubject(state: CampaignState, subject: string): void {
            state.newCampaign.subject = subject;
        },

        setNewCampaignTrigger(state: CampaignState, trigger: CampaignTrigger): void {
            state.newCampaign.trigger = trigger;
        },

        setNewCampaignTriggerStartDate(state: CampaignState, startDate: string): void {
            if (!state.newCampaign.trigger) {
                throw new Error("trigger cannot be undefined");
            }
            state.newCampaign.trigger.startDate = startDate;
        },

        setNewCampaignTriggerEndDate(state: CampaignState, endDate: string): void {
            if (!state.newCampaign.trigger) {
                throw new Error("trigger cannot be undefined");
            }
            state.newCampaign.trigger.endDate = endDate;
        },

        setNewCampaignTriggerScheduleType(state: CampaignState, scheduleType: CampaignScheduleType): void {
            if (!state.newCampaign.trigger) {
                throw new Error("trigger cannot be undefined");
            }
            state.newCampaign.trigger.scheduleType = scheduleType;
        },

        setNewCampaignTriggerFrequency(state: CampaignState, frequency: number): void {
            if (!state.newCampaign.trigger) {
                throw new Error("trigger cannot be undefined");
            }
            state.newCampaign.trigger.frequency = frequency;
        },

        setNewCampaignTriggerDaysAfter(state: CampaignState, daysAfter: number): void {
            if (!state.newCampaign.trigger) {
                throw new Error("trigger cannot be undefined");
            }
            state.newCampaign.trigger.daysAfter = daysAfter;
        },

        setNewCampaignTriggerStoreServiceCategoryId(state: CampaignState, serviceCategoryId: number): void {
            if (!state.newCampaign.trigger) {
                throw new Error("trigger cannot be undefined");
            }
            state.newCampaign.trigger.storeServiceCategoryId = serviceCategoryId;
        },

        setNewCampaignTemplate(state: CampaignState, template: string): void {
            state.newCampaign.template = template;
        }
    },

    actions: {
        async searchCampaigns(context: ActionContext<CampaignState, MarketingState>, cancelToken?: CancelToken): Promise<Campaign[]> {
            let filter = `contains(Name, '${context.state.campaignsQuery || ""}')`;
            let campaignType = "";
            if (context.state.campaignsFilterBy.includes("published")) {
                filter += ` and ${context.state.campaignsFilterBy}`;
            } else {
                campaignType = context.state.campaignsFilterBy;
            }
            const orderDirection = context.state.campaignsOrderBy === "modified" ? "desc" : "asc";

            const campaigns = await getCampaigns(filter, context.state.campaignsOrderBy, orderDirection, campaignType, cancelToken);
            context.commit("setCampaigns", campaigns);
            return campaigns;
        },

        async loadCampaign(context: ActionContext<CampaignState, MarketingState>, id: number): Promise<Campaign> {
            const campaign = await getCampaign(id);
            context.commit("setCurrentCampaign", JSON.parse(JSON.stringify(campaign)));
            context.commit("setNewCampaign", JSON.parse(JSON.stringify(campaign)));
            return campaign;
        },

        async createCampaign(context: ActionContext<CampaignState, MarketingState>): Promise<Campaign> {
            const campaign = await postCampaign(context.state.newCampaign);
            context.commit("setCurrentCampaign", JSON.parse(JSON.stringify(campaign)));
            context.commit("setNewCampaign", JSON.parse(JSON.stringify(campaign)));
            return campaign;
        },

        async updateCampaign(context: ActionContext<CampaignState, MarketingState>): Promise<Campaign> {
            if (!context.state.currentCampaign.id) {
                throw new Error("currentCampaign hasn't been loaded");
            }

            if (!context.state.newCampaign.id) {
                throw new Error("newCampaign hasn't been loaded");
            }

            const campaign = await patchCampaign(context.state.currentCampaign, context.state.newCampaign);
            context.commit("setCurrentCampaign", JSON.parse(JSON.stringify(campaign)));
            context.commit("setNewCampaign", JSON.parse(JSON.stringify(campaign)));
            return campaign;
        },

        async deleteCampaign(context: ActionContext<CampaignState, MarketingState>): Promise<void> {
            if (!context.state.currentCampaign.id) {
                throw new Error("currentCampaign hasn't been loaded");
            }

            await deleteCampaign(context.state.currentCampaign.id);
            context.commit("removeCampaign", context.state.currentCampaign.id);
            context.commit("setCurrentCampaign", {});
            context.commit("setNewCampaign", {});
        }
    }
};

export default campaignModule;
