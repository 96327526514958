/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import { compare } from "fast-json-patch";

export default {
    namespaced: true,
    state: {
        newReward: {},
        rewards: [],
        getLoading: false,
        postLoading: false
    },
    actions: {
        async getRewards({ commit }) {
            commit("setGetLoading", true);
            const response = await axios({
                method: "GET",
                url: "v1/Reward"
            });

            for (const reward of response.data.items) {
                if (reward.startDateUtc) {
                    reward.startDateUtc = reward.startDateUtc.split(
                        "T"
                    )[0];
                }
                if (reward.endDateUtc) {
                    reward.endDateUtc = reward.endDateUtc.split("T")[0];
                }
            }
            commit("setRewards", response.data.items);
            commit("setGetLoading", false);
        },
        async saveReward({ state, rootState, commit, dispatch }) {
            commit("setPostLoading", true);
            commit("setNewReward", { ...state.newReward });

            if (state.newReward.rewardId) {
                const oldRewardIndex = state.rewards.findIndex(
                    (reward) =>
                        reward.rewardId ===
                        state.newReward.rewardId
                );
                const oldReward = state.rewards[oldRewardIndex];
                const patchDocument = compare(
                    oldReward,
                    state.newReward
                );
                await axios.patch(`v1/Reward/${state.newReward.rewardId}`,
                    patchDocument);
            } else {
                await axios({
                    method: "POST",
                    url: "v1/Reward",
                    data: state.newReward
                });
            }

            dispatch("getRewards");

            commit("setNewReward", {});
            commit("setPostLoading", false);
        },
        async deleteReward({ state, commit }, rewardId) {
            await axios({
                method: "DELETE",
                url: `v1/Reward/${rewardId}`
            });

            const rewards = state.rewards.filter(
                (reward) =>
                    reward.rewardId !== rewardId
            );
            commit("setRewards", rewards);
        },
        duplicateReward({ dispatch, commit }, reward) {
            reward.rewardId = null;
            commit("setNewReward", reward);
            dispatch("saveReward");
        }
    },
    getters: {

    },
    mutations: {
        setGetLoading(state, getLoading) {
            state.getLoading = getLoading;
        },
        setPostLoading(state, postLoading) {
            state.postLoading = postLoading;
        },
        setRewards(state, rewards) {
            state.rewards = rewards;
        },
        setNewReward(state, newReward) {
            state.newReward = newReward;
        }
    }
};
