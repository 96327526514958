/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        loading: false,
        config: {},
        rewards: [],
        campaigns: [],
        campaignEvents: []
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        setCampaigns(state, campaigns) {
            state.campaigns = campaigns;
        },
        setCampaignEvents(state, events) {
            state.campaignEvents = events;
        },
        setRewards(state, rewards) {
            state.rewards = rewards;
        },
        setConfig(state, config) {
            state.config = config;
        },
        mergeRewards: (state, rewards) => {
            rewards.forEach(reward => {
                Vue.set(state.rewards, reward.rewardOfferId, reward);
            });
        }
    },
    actions: {
        async loadCampaigns({ commit, state }) {
            if (state.campaigns.length > 0)
                return;
            try {
                commit("setLoading", true);
                const response = await axios({
                    method: "GET",
                    url: "v1/CampaignTracking"
                });

                if (response.status === 200) {
                    commit("setCampaigns", response.data);
                }
            } finally {
                commit("setLoading", false);
            }
        },
        async loadCampaignEvents({ commit, state }) {
            if (state.campaignEvents.length > 0)
                return;
            try {
                commit("setLoading", true);
                const response = await axios({
                    method: "GET",
                    url: "v1/CampaignTracking/GetEvents"
                });

                if (response.status === 200) {
                    commit("setCampaignEvents", response.data);
                }
            } finally {
                commit("setLoading", false);
            }
        },
        async loadRewardsByDate({ commit, state }, request) {
            if (state.rewards.length > 0)
                return;
            try {
                commit("setLoading", true);

                const response = await axios.get(`v1/LoyaltyProgramRewardSummary/GetByDate?startDate=${request.startDate}&endDate=${request.endDate}`);
                if (response.status === 200) {
                    commit("setRewards", response.data);
                }
            } finally {
                commit("setLoading", false);
            }
        },
        async loadConfig({ commit }) {
            try {
                commit("setLoading", true);
                // const response = await axios.get("v1/RewardsTracking/GetRewardsData");
                // if (response.status === 200) {
                //     commit("setRewards", response.data);
                // }
            } finally {
                commit("setLoading", false);
            }
        }
    },
    getters: {
        getChartLoading: state => state.loading,
        getCampaignData: state => state.campaigns,
        getCampaignEventData: state => state.campaignEvents,
        getRewardData: state => state.rewards,
        getConfiguration: state => state.config
    }
};
