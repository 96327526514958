import { isInRole } from "@/utilities";
import { NavigationGuardNext, Route } from "vue-router";

export async function roleNavigationGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
    if (to.matched.some(record => record.meta.role && !isInRole(record.meta.role))) {
        next({ name: "forbidden" });
    } else {
        next();
    }
}
