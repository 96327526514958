import { Campaign } from "@/models";
import { Page } from "@points/common";
import axios, { CancelToken } from "axios";
import { compare } from "fast-json-patch";

export async function getCampaigns(filter = "", orderBy = "", orderDirection: "asc" | "desc" = "asc", type = "", cancelToken?: CancelToken): Promise<Campaign[]> {
    const response = await axios.get<Page<Campaign>>(`${process.env.VUE_APP_CENTRAL}/marketing/api/campaigns`, {
        params: {
            $filter: filter,
            $orderBy: `${orderBy} ${orderDirection}`,
            type
        },
        cancelToken: cancelToken
    });
    return response.data.items;
}

export async function getCampaign(id: number): Promise<Campaign> {
    if (!id) {
        throw new Error("id is required");
    }

    const response = await axios.get<Campaign>(`${process.env.VUE_APP_CENTRAL}/marketing/api/campaigns/${id}`);
    return response.data;
}

export async function postCampaign(campaign: Campaign): Promise<Campaign> {
    const response = await axios.post<Campaign>(`${process.env.VUE_APP_CENTRAL}/marketing/api/campaigns`, campaign);
    return response.data;
}

export async function patchCampaign(oldCampaign: Campaign, newCampaign: Campaign): Promise<Campaign> {
    const patchDocument = compare(oldCampaign, newCampaign);
    const response = await axios.patch<Campaign>(`${process.env.VUE_APP_CENTRAL}/marketing/api/campaigns/${newCampaign.id}`, patchDocument);
    return response.data;
}

export async function deleteCampaign(id: number): Promise<void> {
    if (!id) {
        throw new Error("id is required");
    }

    await axios.delete(`${process.env.VUE_APP_CENTRAL}/marketing/api/campaigns/${id}`);
}
