import Vue from "vue";

/**
 * Filter for displaying currency
 * @param {number} value
 */
Vue.filter("currency", function(value) {
    if (!value)
        return "";
    return value >= 0 ?
        "$" + value.toLocaleString("en-US", { minimumFractionDigits: 2 }) :
        `-$${(-1.0 * value).toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
});
