// Dyanmic imports have no return type
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Role } from "@/models";
import { configureErrorRouter, configureOidcRouter, configureVersionRouter } from "@points/common";
import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
import { authNavigationGuard } from "./auth-navigation-guard";
import { roleNavigationGuard } from "./role-navigation-guard";

Vue.use(Router);

const routes: RouteConfig[] = [
    {
        name: "home",
        path: "/",
        component: () => import("@/views/HomeView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    },
    {
        name: "campaigns",
        path: "/campaigns",
        component: () => import("@/views/CampaignView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    },
    {
        name: "campaignEdit",
        path: "/campaigns/:id",
        component: () => import("@/views/CampaignEditView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin },
        props: true
    },
    {
        name: "campaignEditContent",
        path: "/campaigns/:id/content",
        component: () => import("@/views/CampaignEditContentView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin },
        props: true
    },
    {
        name: "customer-lists",
        path: "/customer-lists",
        component: () => import("@/views/CustomerListsView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    },
    {
        name: "promotions",
        path: "/promotions",
        component: () => import("@/views/PromotionsView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    },
    {
        name: "rewards",
        path: "/rewards",
        component: () => import("@/views/RewardsView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    },
    {
        name: "loyalty-programs",
        path: "/loyalty-programs",
        component: () => import("@/views/LoyaltyProgramsView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    },
    {
        name: "charts",
        path: "/charts",
        component: () => import("@/views/ChartsView.vue"),
        meta: { requiresAuth: true, Role: Role.Admin }
    }
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

configureOidcRouter(router);
router.beforeEach(authNavigationGuard);
router.beforeEach(roleNavigationGuard);
configureErrorRouter(router);
configureVersionRouter(router);

export default router;
